import { format } from "date-fns";
import Cookies from "js-cookie";
import { selector, selectorFamily, atom } from "recoil";
import { IResponseModel } from "../models/ResponseModel";


const token = Cookies.get('token');
export interface IPatietnsModel {
    id: string,
    attributes: {
        name: string;
        last_name: string;
        last_name2: string;
        street: string;
        neighborhood: string;
        zip_code: number;
        phone: string;
        curp: string;
        birthdate: Date;
        appointment: string;
        clinic_id: number;
        seller: {
            created_at: "2021-08-28T17:52:05.000000Z"
            curp: "ROCC730912HASDR01"
            email: "cesar3460@yahoo.com.mx"
            email_verified_at: "2021-08-28T17:52:05.000000Z"
            id: 3
            name: "Cesar Enrique Rodríguez Cortés"
            phone: "+524492364368"
        };
        clinic: {
            address: "Calle Bach #103 Col. León Moderno CP 37480"
            city: "LEÓN"
            created_at: "2021-09-15T02:42:37.000000Z"
            id: 4
            image: "https://lh3.googleusercontent.com/pw/AM-JKLWESEtMYsWOV7Nsnd12bV3ZbC3Lrk0jwY_6VMZsse6fYijPTGQMhIMFxhjqnU6BHo9CJ9rHcI8meKZDqUOSkkeTmr-LEURlncq3N3Ph8Nrq1tM56Eug6KVPXEIrfn53k97i4501I5oWod3-GPdYkTtB=w1277-h903-no?authuser=0"
            location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3722.0741746086073!2d-101.66972117941!3d21.10960878733086!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842bbfaefcc08e37%3A0x105fce3c91954497!2sBach%20103%2C%20Le%C3%B3n%20Moderno%2C%2037480%20Le%C3%B3n%2C%20Gto.!5e0!3m2!1ses-419!2smx!4v1630111665317!5m2!1ses-419!2smx"
            location_for_whats: "https://goo.gl/maps/hHbw7FC7rAz9tEbSA"
            name: "ROAL"
            phone: "4778306106,4778306206,4773768626"
            state: "GTO."
            total_appointment: 20
            total_appointment_afternoon: 20
            updated_at: "2021-09-15T02:42:37.000000Z"
        };
        update_by: string;
        have_drive_course: string;
        want_more_info: string;
        contact_type: string;
        status: string;
        appointment_turn: number;
        schedule: string;
    }
};

export const selectedPatient = atom({
    key: 'patientById',
    default: {} as IResponseModel<IPatietnsModel>
});

export const patientsByDate = atom({
    key: 'patientsByDate',
    default: [] as IPatietnsModel[]
});

const getPatients = async ()  => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/patients`);
    return response.json();
};

const getPatientsByCurp = async (id: any)  => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/patients/${id}`);
    return response.json();
};

const getPatientsByDate = async (params: any) => {
    const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/admin/patients?${params.uuid}&filter=clinic_id:${params.id},appointment:${format(params.date, 'yyyy-MM-dd')}`,
        { 
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Authorization': `bearer ${token}` || ''
            }
        }
    );
    return response.json();       
};

const savePatient = async (params: any) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/patients`, {
        method: 'POST',
        body: JSON.stringify(params),
        headers:{
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    });
    return response;
}

const updatePatient = async (params: any) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/patients/${params.id}`, {
        method: 'PUT',
        body: JSON.stringify(params.attributes),
        headers:{
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    });
    return response;
}

export const getPatientsQuery = selector<IResponseModel<IPatietnsModel[]>>({
    key: 'getPatients',
    get: async({get}) => {
        const response = await getPatients();
        return response;
    }
});

export const savePatientQuery = selectorFamily({
   key: 'savePatient',
   get: params => async ({get}) => {
        const response =  await savePatient(params);
        return await response.json();
   },
   set: params =>  ({set}) => {
        // savePatient(params);
   },
});

export const getPatientByCurpQuery = selectorFamily({
    key: 'getPatientByCurp', 
    get: params => async ({get}) => {

        const response =  await getPatientsByCurp(params);   
        return response.data;
    },
    set: params => ({set, get}, val) => {

    },
});

export const getPatientByIdQuery = selectorFamily({
    key: 'getPatientById', 
    get: params => async ({get}) => {

        const response =  await getPatientsByDate(params);   
        return response.data;
    },
    set: params => ({set, get}, val) => {

    },
});


export const updatePatientQuery = selectorFamily({
    key: 'updatePatient',
    get: params => async ({get}) => {
         const response =  await updatePatient(params);
         return await response.json();
    },
    set: params =>  () => {
         savePatient(params);
    },
 });


