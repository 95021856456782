import Cookies from "js-cookie";
import { selector, selectorFamily } from "recoil";
import { IResponseModel } from "../models/ResponseModel";
export interface IUser {
    address: string;
    city: string;
    created_at: string;
    curp: string;
    exp_date: string;
    id: number;
    last_name: string;
    last_name2: string;
    licence_category: string;
    name: string;
    no_exp: string;
    phone: string;
    state: string;
    updated_at: string;
    vigency_from: string;
    vigency_to: string;
} 
 
const token = Cookies.get('token');

const getUsers = async ()  => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/directories`,{
        method: 'GET',
        headers:{
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `bearer ${token}`,
        }
    });
    return response.json();
};

const saveUser = async (params: any) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/directories`, {
        method: 'POST',
        body: JSON.stringify(params),
        headers:{
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `bearer ${token}`,
        }
    });
    return response;
}

const deleteUser = async (params: any) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/directories/`+params, {
        method: 'DELETE',
        headers:{
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `bearer ${token}`,
        }
    });
    return response;
}

export const getUsersQuery = selector<IResponseModel<IUser[]>>({
    key: 'getUsers',
    get: async({get}) => {
        const response = await getUsers();
        return response;
    }
});

export const saveUserQuery = selectorFamily({
    key: 'saveUser',
    get: params => async ({get}) => {
         const response =  await saveUser(params);
         return await response.json();
    },
 });

 export const deleteUserQuery = selectorFamily({
    key: 'deleteUser',
    get: params => async ({get}) => {
         const response =  await deleteUser(params);
         return await response.json();
    },
 });