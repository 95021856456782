import { Box, Button,  Select, MenuItem, Grid, Table, TableHead, TableBody, TableRow, TableCell, SelectChangeEvent, Link, Tabs, Tab } from '@material-ui/core';
import React from 'react';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import { getClinicByIdQuery, getClinisAdminQuery, IClinicsModel, selectedClinic } from '../../services/ClinicServices';
import DatePickerComponent from '../../components/DatePickerComponent'
import { getPatientByIdQuery, IPatietnsModel, patientsByDate, updatePatientQuery } from '../../services/PatientServices';
import { format, isBefore } from 'date-fns';
import { IResponseModel } from '../../models/ResponseModel';
import SearchIcon from '@material-ui/icons/Search';
import './style.css';
import { StatusEnum } from '../../enums/StatusEnum';
import { v4 as uuidv4 } from 'uuid';

import DescriptionIcon from '@material-ui/icons/Description';
import ReportBarChart from './ReportCharts/ReportBarChart';

const AdminPanel = () => {
    const hospitals = useRecoilValue(getClinisAdminQuery);
    const [ date, setDate ] = React.useState<Date | null>(new Date());
    const [ tabValue, setTabValue ] = React.useState<number>(0);
    const tableRef = React.useRef<HTMLDivElement>(null);
    const [ selectedHospital, setSelectedHospital ]  = useRecoilState(selectedClinic);
    const [ selectedPatients, setSelectedPatients ] = useRecoilState(patientsByDate);
    const getPatientById = useRecoilCallback(({set, snapshot}) =>  async (id: number, date: Date | null, uuid: any) => {
        const response = await snapshot.getPromise(getPatientByIdQuery({id, date, uuid}));
        return response;
    });
    const getClinicById = useRecoilCallback(({set, snapshot}) =>  async (id: string) => {
        const response = await snapshot.getPromise(getClinicByIdQuery(id));
        return response;
    });

    const updatePatientById = useRecoilCallback(({set, snapshot}) => async (patient: any) => {
        await snapshot.getPromise(updatePatientQuery(patient));
        const patientResponse = await getPatientById(Number(selectedHospital.data?.id), date, uuidv4());
        setSelectedPatients(patientResponse as IPatietnsModel[]);
    });

    const handleOnChangeDate = async (arg: Date | null) => {
        setDate(arg);
    };

    const handleOnChange = async (event: SelectChangeEvent<string>, child: React.ReactNode) => {
        const clinicResponse = await getClinicById(event.target.value);
        setSelectedHospital(clinicResponse as IResponseModel<IClinicsModel>);
    };

    const handleOnSearch = async () => {
        setSelectedPatients([] as IPatietnsModel[]);
        const patient = await getPatientById(Number(selectedHospital.data?.id), date, uuidv4());
        setSelectedPatients(patient as IPatietnsModel[]);
    };

    const handleOnChangeStatus = async (e: SelectChangeEvent<any>, index: number, patient: IPatietnsModel) => {
        await updatePatientById({...patient, attributes: {...patient.attributes , status: Number(e.target.value)} });
    };
 
    const handleOnPrint = () => {
        window.print();
    };

    const handleTabChange = (e: React.SyntheticEvent<Element, Event>, value: number) => {
        e.preventDefault();
        setTabValue(value);
    }

    const handleOnShowReports = (selectedHospital: IResponseModel<IClinicsModel>): boolean => {
        try {
            console.log(selectedHospital?.data?.attributes?.report_link);
            JSON.parse(selectedHospital?.data?.attributes?.report_link);
        } catch (e) {
            return false;
        }
        return true;
    }

    return (
        <Box>
            <Grid container style={{paddingTop: '1rem'}}>
                <Grid className="no-printme" item xs={4}>
                    <Box marginTop={5} display="flex" justifyContent="flext-start" align-items="flex-start">
                        <DatePickerComponent handleOnChangeDate={handleOnChangeDate} />
                    </Box>
                    <Box marginLeft="20px">
                        <Select 
                            onChange={handleOnChange} 
                            defaultValue="none"
                            style={{maxWidth: '280px'}}
                            value={selectedHospital?.data?.id} 
                            placeholder="Clínica">
                            <MenuItem value="none" disabled>
                                <span>Seleccione la Clínica</span>
                            </MenuItem>
                            {hospitals?.data.map(x => 
                                <MenuItem value={Number(x.id)} key={x.id}>
                                    {x.attributes.name}
                                </MenuItem>
                            )}
                        </Select>
                    </Box>
                    <Box marginTop="1rem" marginLeft="20px" >
                        <Button startIcon={<SearchIcon />} variant="contained" onClick={handleOnSearch}>Buscar</Button>
                    </Box>
                    <Box marginTop="2rem" marginLeft="20px">
                        {
                        selectedHospital?.data?.attributes?.report_link && handleOnShowReports(selectedHospital) ?
                        <Box>
                            <Link href={JSON.parse(selectedHospital?.data?.attributes?.report_link).morning} target="_blank">
                                <Button startIcon={<DescriptionIcon />}>
                                    Reporte de Clínica (Matutino)
                                </Button>
                            </Link>
                            <Link href={JSON.parse(selectedHospital?.data?.attributes?.report_link).afternoon} target="_blank">
                                <Button startIcon={<DescriptionIcon />}>
                                    Reporte de Clínica (Vespertino)
                                </Button>
                            </Link>
                        </Box>
                        : selectedHospital?.data?.attributes?.report_link && 
                            <Link href={selectedHospital?.data?.attributes?.report_link} target="_blank">
                                <Button startIcon={<DescriptionIcon />}>
                                    Sistema General de Clínica 
                                </Button>
                            </Link>
                        }
                    </Box>
                </Grid>
                {selectedPatients?.length > 0 && (
                <Grid item xs={8}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <Tabs value={tabValue} onChange={handleTabChange}>
                            <Tab label="Reporte" value={0} /> 
                            <Tab label="Gráfica" value={1} />
                        </Tabs>
                    </Box>
                    <Box hidden={tabValue !== 0} className="printme" ref={tableRef}>
                        <Table aria-label="a dense table" >
                            <TableHead>
                            <TableRow>
                                <TableCell colSpan={2}>
                                    <Box>{selectedHospital.data?.attributes?.name}</Box>
                                    <Box>Fecha: {format(date || new Date(), 'dd / MM / yyyy')}</Box>
                                </TableCell>
                            </TableRow>
                                <TableRow>
                                    <TableCell>Folio</TableCell>
                                    <TableCell>Nombre(s)</TableCell>
                                    <TableCell align="right">CURP</TableCell>
                                    <TableCell>Teléfono</TableCell>
                                    <TableCell>Contacto</TableCell>
                                    <TableCell>Turno</TableCell>
                                    <TableCell>Estatus</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                            {selectedPatients?.map((row, i) => (
                                <TableRow hover key={row.attributes.name}>
                                <TableCell component="th" scope="row">
                                    {row.attributes.appointment_turn}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {`${row.attributes.name} ${row.attributes.last_name}`}
                                </TableCell>
                                <TableCell align="left">{row.attributes.curp}</TableCell>
                                <TableCell component="th" scope="row">
                                    <a href={`tel:${row.attributes.phone}`}>{row.attributes.phone}</a>
                                </TableCell>
                                <TableCell>
                                    {row.attributes.seller?.name || row.attributes.contact_type}
                                </TableCell>
                                <TableCell>
                                    {row.attributes?.schedule || 'Matutino'}
                                </TableCell>
                                <TableCell>
                                    <div>
                                    {isBefore( Date.parse(row.attributes.appointment), new Date().getDate()) ? <span>{StatusEnum[Number(row.attributes.status) - 1]}</span> : 
                                        <Select value={row.attributes.status} onChange={e => handleOnChangeStatus(e, i, row)}>
                                            <MenuItem value="1">Seleccionar </MenuItem>
                                            <MenuItem value="2">Asistió</MenuItem>
                                            <MenuItem value="3">No Asistió</MenuItem>
                                            <MenuItem value="4">Cancelado</MenuItem>
                                        </Select>
                                    }
                                    </div>
                                </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </Box>
                    <Box hidden={tabValue !== 1}>
                        <ReportBarChart users={selectedHospital.data?.attributes?.users} />
                    </Box>
                    <Box marginTop="2rem">
                        <Button variant="contained" onClick={handleOnPrint}>
                            Imprimir
                        </Button>
                    </Box>
                </Grid>)}
            </Grid>
        </Box>
    );
};

export default AdminPanel;