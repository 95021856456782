import { 
    Button, 
    Box, 
    Dialog, 
    DialogContent, 
    DialogTitle, 
    TextField, 
    FormControl, 
    Radio, 
    RadioGroup, 
    FormControlLabel, 
    Grid, 
    FormLabel, 
    Typography, 
    Autocomplete
} from '@material-ui/core';
import { format } from 'date-fns';
import * as yup from "yup";
import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import { IClinicsModel } from '../services/ClinicServices';
import DatePickerComponent from './DatePickerComponent';
import { v4 as uuidv4 } from 'uuid';

import states from '../assets/datasets/states.json';

interface DatePickerModalComponentProps {
    isDatePickerOpen: boolean;
    clinic: IClinicsModel;
    handleOnClose: () => void;
    handleOnSave: (x:any) => void;
}

const schema = yup.object().shape({
    name: yup.string().required(),
    curp: yup.string().min(18).required(),
});

const DatePickerModalComponent:React.FC<DatePickerModalComponentProps> = props => {
    const { control, getValues, setValue } = useForm({
        resolver: yupResolver(schema)
    });
    const {isDatePickerOpen, clinic, handleOnClose, handleOnSave} = props;
    const [ selectedOption, setSelectedOption ] = useState<string>('Vendedor');
    const [ selectedTurn, setSelectedTurn ] = useState<string>('Matutino')
    const [availableDates, setAvailableDates] = useState<number>();
    const [availableAfterDates, setAvailableAfterDates] = useState<number>();
    const [uuid, setUuid] = useState<any>(uuidv4);

    useEffect(() => {
        setValue('birthdate',  format(new Date(), 'yyyy-MM-dd'));
        setValue('appointment', format(Date.now(), 'yyyy-MM-dd'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOnSubmit =  (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if(schema.isValidSync(getValues())) {
            setValue('clinic_id', clinic.id);
            setValue('valoration', '1');
            setValue('contact_type', selectedOption);
            setValue('schedule', selectedTurn);
            handleOnSave(getValues());
            setUuid(uuidv4);
        }
        
    };
    const handleOnChangeDate = (date: Date | null, available: number | undefined, available_after: number | undefined) => {
        setValue('appointment', format(date || new Date(), 'yyyy-MM-dd'));
        setAvailableDates(available || clinic?.attributes?.total_appointment);
        setAvailableAfterDates(available_after || clinic?.attributes?.total_appointment_afternoon);
    };

    return (
        <Box key={uuid}>
            <Dialog onClose={handleOnClose} maxWidth="lg" open={isDatePickerOpen}>
                <Grid container style={{padding: '1rem'}}>
                    <Grid item xs={12} sm={4}>
                        <Box>
                            <DialogTitle>
                                <Typography>Clínica {clinic?.attributes?.name?.split('-')[0]}</Typography>
                                <Typography variant="caption">{clinic?.attributes?.name?.split('-')[1]}</Typography>
                            </DialogTitle>
                            <DialogContent dividers >
                                <DatePickerComponent clinic={clinic} handleOnChangeDate={handleOnChangeDate} limit/>
                            </DialogContent>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={8} >
                        <Box marginTop={2}>
                            <Typography>{ !availableAfterDates ? 
                            (<span>Lugares Disponibles: {availableDates || clinic?.attributes?.total_appointment}</span>):
                                (<div>
                                    <span>Matutino: {availableDates} </span>  
                                    <span>Vespertino: {availableAfterDates} </span>
                                </div>)
                            }</Typography>
                            
                            <Box marginBottom={5}/>
                            <form onSubmit={e => handleOnSubmit(e)}>
                                <Controller 
                                    control={control}
                                    name="apto_med"
                                    render={({ field }) => <TextField 
                                        {...field} 
                                        variant="standard" 
                                        label="No. Medicina Preventiva"
                                        inputProps={{maxLength: 80}}
                                    />}
                                />
                                <Controller 
                                    control={control}
                                    rules={{required: true}}
                                    name="name"
                                    render={({ field }) => <TextField 
                                        {...field} 
                                        variant="standard"
                                        inputProps={{maxLength: 80}} 
                                        label="Nombre(s)" 
                                        required 
                                    />}
                                />
                                <Controller 
                                    name="last_name" 
                                    control={control} 
                                    render={({ field }) => 
                                        <TextField {...field} 
                                            variant="standard" 
                                            label="Apellido Paterno"
                                            inputProps={{maxLength: 80}}
                                            required
                                        />
                                    }
                                />
                                <Controller 
                                    name="last_name2" 
                                    control={control} 
                                    render={({ field }) => 
                                    <TextField 
                                        {...field} 
                                        variant="standard" 
                                        label="Apellido Materno"
                                        inputProps={{maxLength: 80}} 
                                        required/>}
                                />
                                <Controller
                                    name="curp" 
                                    control={control} 
                                    render={({ field }) => 
                                    <TextField 
                                        {...field} 
                                        variant="standard" 
                                        label="CURP"
                                        helperText={<span>Consulta tu CURP <a href="https://www.gob.mx/curp/" rel="noreferrer" target="_blank">aquí</a></span>}
                                        inputProps={{
                                            maxLength: 18,
                                            minLength: 18
                                        }}
                                        required
                                    />}
                                />
                                <Controller 
                                    name="phone" 
                                    control={control} 
                                    render={({ field }) => 
                                    <TextField {...field} 
                                        inputProps={{maxLength: 10}}
                                        InputProps={{startAdornment: '+521'}}
                                        variant="standard" 
                                        label="Teléfono" 
                                        required
                                    />
                                    }
                                />
                                
                                <Controller 
                                    name="state"
                                    control={control}
                                    render={({ field }) => <Autocomplete
                                        {...field}
                                        inputValue={field.value}
                                        onChange={(event, newValue) => setValue('state', newValue?.id)}
                                        options={states.map(x => {return {label: x.nombre, id: x.nombre}})}
                                        renderInput={(params) => <TextField  
                                            {...params} 
                                            variant="standard" 
                                            label="Selecciona Estado" 
                                            required
                                        />}
                                    />}
                                />
                                
                                <Controller 
                                    name="municipality" 
                                    control={control} 
                                    render={({ field }) => 
                                    <TextField {...field} 
                                        inputProps={{maxLength: 80}}
                                        variant="standard" 
                                        label="Municipio" 
                                        required
                                    />}
                                />

                                <Box marginTop={2}>
                                    <FormControl component="fieldset" >
                                        <RadioGroup row>
                                            <FormControlLabel value="1" control={<Radio />} label="Valoración (Nuevo Ingreso)" />
                                            <FormControlLabel value="2" control={<Radio />} label="Revaloración (Refrendo)" />
                                        </RadioGroup>
                                    </FormControl>
                                </Box>
                                {clinic?.attributes?.total_appointment_afternoon > 0 && <Box marginTop={2}>
                                    <FormControl component="fieldset" >
                                        <FormLabel>Turno</FormLabel>
                                        <RadioGroup onChange={e => setSelectedTurn(e.target.value)} value={selectedTurn} row>
                                            <FormControlLabel value="Matutino" control={<Radio />} label="Matutino" />
                                            <FormControlLabel value="Vespertino" control={<Radio />} label="Vespertino" />
                                        </RadioGroup>
                                    </FormControl>
                                </Box>}
                                <Box marginTop={2}>
                                    <FormControl component="fieldset" >
                                        <FormLabel>¿Cómo te enteraste de nosotros?</FormLabel>
                                        <RadioGroup onChange={e => setSelectedOption(e.target.value)} value={selectedOption} row>
                                            <FormControlLabel value="Vendedor" control={<Radio />} label="Vendedor" />
                                            <FormControlLabel value="Internet" control={<Radio />} label="Internet" />
                                            <FormControlLabel value="Whatsapp" control={<Radio />} label="Whatsapp" />
                                            <FormControlLabel value="Clínica" control={<Radio />} label="Clínica" />
                                            <FormControlLabel value="Escuela" control={<Radio />} label="Escuela" />
                                            <FormControlLabel value="Empresa" control={<Radio />} label="Empresa" />
                                        </RadioGroup>
                                    </FormControl>
                                </Box>
                                {selectedOption === 'Vendedor' && 
                                <Box marginBottom={5}>
                                
                                <Controller 
                                    name="seller"
                                    control={control}
                                    render={({ field }) => <Autocomplete
                                        {...field}
                                        inputValue={field.value}
                                        onChange={(event, newValue) => setValue('seller_id', newValue?.id)}
                                        options={clinic?.attributes?.users.map(x => {return {label: x.name, id: x.id}})}
                                        renderInput={(params) => <TextField  
                                            required={selectedOption === 'Vendedor'}
                                            {...params} 
                                            variant="standard" 
                                            label="Selecciona Vendedor" />
                                        }
                                    />}
                                />
                                </Box>}
                                {selectedOption === 'Empresa' && 
                                    <Box marginBottom={5}>
                                        <Controller 
                                            name="company" 
                                            control={control} 
                                            render={({ field }) => <TextField {...field} variant="standard" label="Empresa" />}
                                        />
                                        <Controller 
                                            name="company_contact" 
                                            control={control} 
                                            render={({ field }) => <TextField {...field} variant="standard" label="Contacto" />}
                                        />
                                        <Controller 
                                            name="company_phone" 
                                            control={control} 
                                            render={({ field }) => <TextField {...field} variant="standard" label="Teléfono" />}
                                        />
                                    </Box>
                                }
                                <Box style={{float: 'right'}}>
                                    <Button type="submit">Guardar Cita</Button>
                                </Box>
                            </form>
                        </Box>
                    </Grid>
                </Grid>
            </Dialog>
        </Box>

    );
};

export default DatePickerModalComponent;