import Cookies from "js-cookie";
import { selector, selectorFamily } from "recoil";


const token = Cookies.get('token');

export const loginCall = async (params: {email: string, password: string})  => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/login`, {
        method: 'POST',
        body: JSON.stringify(params),
        headers:{
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
    });
    return response.json();
};

export const getMe = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/me`, {
        method: 'POST',
        headers:{
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': `bearer ${token}`,
        },
    });
    return response.json();
};

export const authLoginQuery = selectorFamily({
    key: 'authLogin', 
    get: (params: {email: string, password: string}) => async ({get}) => {
        const response =  await loginCall(params);
        return response;
    },
    set: params =>  () => {
        loginCall(params);
   },
});


export const getMeQuery = selector<any>({
    key: 'getMe',
    get: async({get}) => {
        const response = await getMe();
        return response;
    }
});
