import { 
    Button, 
    Box, 
    Dialog, 
    DialogContent, 
    DialogTitle, 
    TextField, 
    Grid, 
    Typography, 
    Autocomplete,
    DialogActions
} from '@material-ui/core';
import * as yup from "yup";
import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import { v4 as uuidv4 } from 'uuid';

import states from '../assets/datasets/states.json';
import { IUser } from '../services/UserServices';
import { useSnackbar } from './snackbar/SnackbarComponent';


interface UserModalComponentProps {
    isModalOpen: boolean;
    handleOnClose: () => void;
    handleOnSave: (x:any) => void;
    usersData: IUser[]
}

const schema = yup.object().shape({
    name: yup.string().required(),
    curp: yup.string().min(18).required(),
});

const UserModalComponent:React.FC<UserModalComponentProps> = props => {
    const { control,getValues,setValue } = useForm({
        resolver: yupResolver(schema)
    });
    const { isModalOpen, handleOnClose, handleOnSave, usersData} = props;
    const [uuid, setUuid] = useState<any>(uuidv4);
    const {openSnackBar} = useSnackbar();
   
    const handleOnSubmit =  (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if(schema.isValidSync(getValues())) {
            if(usersData.find(x => x.curp === getValues("curp")) === undefined){
                handleOnSave(getValues());
                setUuid(uuidv4);
            }else{
                alert('Error de validación, La curp ya fue registrada');
                openSnackBar('Error de validación, Verifica los campos' , 'error');
            }            
        }
    };
    return (
        <Box key={uuid}>
            <Dialog onClose={handleOnClose} maxWidth="lg" open={isModalOpen}>
                <form onSubmit={e => handleOnSubmit(e)}>
                    <DialogTitle>
                        <Typography> Agregar Usuario</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container style={{padding: '1rem'}} spacing={2}>
                            <Grid item xs={3} sm={3} style={{paddingBottom: '1rem'}}>
                                <Controller 
                                    control={control}
                                    name="no_exp"
                                    render={({ field }) => <TextField 
                                        {...field} 
                                        variant="standard" 
                                        label="No. Expediente"
                                        inputProps={{maxLength: 80}}
                                        style={{width:'100%'}}
                                        required
                                    />}
                                />
                            </Grid>
                            <Grid item xs={3} sm={3} style={{paddingBottom: '1rem'}}>
                                <Controller 
                                    control={control}
                                    rules={{required: true}}
                                    name="name"
                                    render={({ field }) => <TextField 
                                        {...field} 
                                        variant="standard"
                                        inputProps={{maxLength: 80}} 
                                        label="Nombre(s)" 
                                        style={{width:'100%'}}
                                        required 
                                    />}
                                />
                            </Grid>
                            <Grid item xs={3} sm={3} style={{paddingBottom: '1rem'}}>
                                <Controller 
                                    name="last_name" 
                                    control={control} 
                                    render={({ field }) => 
                                        <TextField {...field} 
                                            variant="standard" 
                                            label="Apellido Paterno"
                                            inputProps={{maxLength: 80}}
                                            style={{width:'100%'}}
                                            required
                                        />
                                    }
                                /> 
                            </Grid>
                            <Grid item xs={3} sm={3} style={{paddingBottom: '1rem'}}>    
                                <Controller 
                                    name="last_name2" 
                                    control={control} 
                                    render={({ field }) => 
                                    <TextField 
                                        {...field} 
                                        variant="standard" 
                                        label="Apellido Materno"
                                        inputProps={{maxLength: 80}} 
                                        style={{width:'100%'}}
                                        required/>}
                                />
                            </Grid>
                        
                            <Grid item xs={8} sm={8} style={{paddingBottom: '1rem'}}>
                                <Controller
                                    name="curp" 
                                    control={control} 
                                    render={({ field }) => 
                                    <TextField 
                                        {...field} 
                                        variant="standard" 
                                        label="CURP"
                                        helperText={<span>Consulta tu CURP <a href="https://www.gob.mx/curp/" rel="noreferrer" target="_blank" style={{color:'blue'}}>aquí</a></span>}
                                        inputProps={{
                                            maxLength: 18,
                                            minLength: 18
                                        }}
                                        style={{width:'100%'}}
                                        required
                                    />}
                                />
                            </Grid>
                            <Grid item xs={4} sm={4} style={{paddingBottom: '1rem'}}>
                                <Controller 
                                    name="phone" 
                                    control={control} 
                                    render={({ field }) => 
                                    <TextField {...field} 
                                        inputProps={{maxLength: 10}}
                                        
                                        variant="standard" 
                                        label="Teléfono" 
                                        style={{width:'100%'}}
                                        required
                                    />
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} style={{paddingBottom: '1rem'}}>
                                <Controller 
                                    control={control}
                                    name="address"
                                    render={({ field }) => <TextField 
                                        {...field} 
                                        variant="standard" 
                                        label="Domicilio"
                                        style={{width:'100%'}}
                                        inputProps={{maxLength: 80}}
                                        required
                                    />}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} style={{paddingBottom: '1rem'}}>
                                <Controller 
                                        name="state"
                                        control={control}
                                        render={({ field }) => <Autocomplete
                                            {...field}
                                            inputValue={field.value}
                                            onChange={(event, newValue) => setValue('state', newValue?.id)}
                                            options={states.map(x => {return {label: x.nombre, id: x.nombre}})}
                                            renderInput={(params) => <TextField  
                                                {...params} 
                                                variant="standard" 
                                                label="Selecciona Estado" 
                                                required
                                            />}
                                        />}
                                    />
                            </Grid>
                            <Grid item xs={6} sm={6} style={{paddingBottom: '1rem'}}>
                                <Controller 
                                        name="city" 
                                        control={control} 
                                        render={({ field }) => 
                                        <TextField {...field} 
                                            inputProps={{maxLength: 80}}
                                            variant="standard" 
                                            label="Municipio" 
                                            style={{width:'100%'}}
                                            required
                                        />}
                                    />  
                            </Grid>

                            <Grid item xs={3} sm={3} style={{paddingBottom: '1rem'}}>
                                <Controller 
                                    control={control}
                                    name="exp_date"
                                    render={({ field }) => <TextField 
                                        {...field} 
                                        variant="standard" 
                                        type="date"
                                        label="Fecha de Examen"
                                        InputLabelProps={{
                                            shrink: true,
                                          }}
                                        inputProps={{maxLength: 80}}
                                        style={{width:'100%'}}
                                    />}
                                />
                            </Grid>
                            <Grid item xs={3} sm={3} style={{paddingBottom: '1rem'}}>
                            <Controller 
                                        name="licence_category" 
                                        control={control} 
                                        render={({ field }) => 
                                        <TextField {...field} 
                                            inputProps={{maxLength: 80}}
                                            variant="standard" 
                                            label="Categoria de Licencia" 
                                            style={{width:'100%'}}
                                            required
                                        />}
                                    />  
                            </Grid>
                            <Grid item xs={3} sm={3} style={{paddingBottom: '1rem'}}>
                                <Controller 
                                    control={control}
                                    name="vigency_from"
                                    render={({ field }) => <TextField 
                                        {...field} 
                                        variant="standard" 
                                        label="Vigencia Desde"
                                        type="date"
                                        InputLabelProps={{
                                            shrink: true,
                                          }}
                                        inputProps={{maxLength: 80}}
                                        style={{width:'100%'}}
                                        required
                                    />}
                                />
                            </Grid>
                            <Grid item xs={3} sm={3} style={{paddingBottom: '1rem'}}>
                                <Controller 
                                    control={control}
                                    name="vigency_to"
                                    render={({ field }) => <TextField 
                                        {...field} 
                                        variant="standard" 
                                        InputLabelProps={{
                                            shrink: true,
                                          }}
                                        label="Vigencia Hasta"
                                        type="date"
                                        inputProps={{maxLength: 80}}
                                        defaultValue=""
                                        style={{width:'100%'}}
                                        required
                                    />}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                            <Button type="submit">Guardar Usuario</Button>
                    </DialogActions>  
                </form>  
            </Dialog>
        </Box>

    );
};

export default UserModalComponent;