import { AppBar, Box, Button, Menu, MenuItem, TextField, Toolbar, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import { getMeQuery } from '../../services/AuthServices';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Cookies from 'js-cookie';
import SearchIcon from '@material-ui/icons/Search';

import { getPatientByCurpQuery } from '../../services/PatientServices';
import FormModalComponent from '../formModal/formModalComponent';
import SnackbarComponent, { useSnackbar } from '../snackbar/SnackbarComponent';
import { Link } from 'react-router-dom';

const Appbar = () => {
    const userMe = useRecoilValue(getMeQuery);
    const {isOpen, message, openSnackBar, severity} = useSnackbar();
    const getPatientByCurp = useRecoilCallback(({set, snapshot}) => async (id: string) => {
      const patients = await snapshot.getPromise(getPatientByCurpQuery(id));
      if(!patients) {
        openSnackBar('No se encontraron registros', 'error');
        return;
      }
      setSelectedRegisteredPatient(patients);
      setIsFormModalOpen(true);
    });
    const [curp, setCurp] = useState<string>('');
    const [isFormModalOpen, setIsFormModalOpen] = React.useState<boolean>(false);
    const [selectedRegisteredPatient, setSelectedRegisteredPatient] = useState<any>();
    const handleOnLogout = () => {
      Cookies.remove('token');
      window.location.href = '/';
    }
    const handleOnChangeCurp = (e: React.ChangeEvent<HTMLInputElement>) => {
      const formattedCurp = e.target.value.toUpperCase().trim();
      setCurp(formattedCurp);
    }
    const handleOnSearchCurp = (e: any) => {
      if(curp.length > 16) {
        if(e.type === 'click') {
          getPatientByCurp(curp);
        }
        if((e.key === '13' || e.key === 'Enter')) {
          getPatientByCurp(curp);
        }
      }
      
    }
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const settings = [{label:'Administración',page:'/admin'}, 
    {label:'Usuarios Registrados',page:'/users'}];
    const handleCloseUserMenu = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElUser(null);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElUser(event.currentTarget);
    };
    return (
        <Box>
            <AppBar position="static">
              <Toolbar>
                  <Typography onClick={() => window.location.href = '/'} style={{cursor: 'pointer'}} variant="h6">
                    Citas Médicas
                  </Typography>
                  <Button color="inherit"></Button>
                  <Box position="absolute" right="20px" display="flex" justifyContent="center" alignItems="center">
                  <TextField
                    className="input-cel" 
                    InputProps={{
                      endAdornment: <SearchIcon style={{cursor:'pointer'}} onClick={handleOnSearchCurp} />
                    }}
                    inputProps={{
                      maxLength: 18
                    }}
                    value={curp}
                    onChange={handleOnChangeCurp}
                    onKeyUp={handleOnSearchCurp}
                    style={{
                      background: '#FFF',
                    }}
                    placeholder="Consulta tu cita (CURP)."
                  />
                  {userMe?.name &&
                    <Box marginLeft="15px" display="flex">
                        <Box marginRight="10px">
                          <Typography onClick={handleOpenUserMenu} style={{cursor: 'pointer'}}> {userMe?.name}</Typography>
                          <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                          >
                            {settings.map((setting) => (
                              <MenuItem key={setting.label} onClick={handleCloseUserMenu} component={Link} to={setting.page} >
                                <Typography textAlign="center">{setting.label}</Typography>
                              </MenuItem> 
                            ))}
                        </Menu>
                        </Box>
                      <ExitToAppIcon onClick={handleOnLogout} style={{cursor: 'pointer'}}  />
                  </Box>}
                  </Box>
                </Toolbar>
            </AppBar>
            <FormModalComponent
              isOpen={isFormModalOpen} 
              handleOnCloseFormModal={() => setIsFormModalOpen(false)}
              patient={selectedRegisteredPatient}
              hideForm
            />
            <SnackbarComponent severity={severity} message={message} isOpen={isOpen} />
        </Box>
    );
};

export default Appbar;
