import { Box, Button, FormControl, Paper, TextField, Typography } from '@material-ui/core';
import Cookies from 'js-cookie';
import React, { useState } from 'react';
import { useRecoilCallback } from 'recoil';
import { authLoginQuery } from '../../services/AuthServices';

const Login = () => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const authLogin = useRecoilCallback(({set, snapshot})=> async (email: string, password: string) => {
        const response = await snapshot.getPromise(authLoginQuery({email, password}));
        if(response?.access_token) {
            Cookies.set('token', response.access_token);
            window.location.href = '/admin';
        } else {
            alert('credenciales invalidas');
        }
        
    });

    const handleGoToAdminPanel = () => {
        authLogin(email, password);
    };
    
    return (
        <Box 
            width="100%" 
            height="100%" 
            display="flex" 
            justifyContent="center" 
            alignItems="center"
            position="absolute"
            left="0"
        >
            <Paper elevation={2}>
                <Box padding="1rem" textAlign="center">
                    <Box marginBottom="1rem">
                        <Typography variant="h6">
                            Ingresar
                        </Typography>
                    </Box>
                    <FormControl>
                        <Box marginTop="1rem">
                            <TextField label="Correo" value={email} onChange={e => setEmail(e.target.value)} required />
                        </Box>
                        <Box marginTop="1rem">
                            <TextField label="Password" value={password} onChange={e => setPassword(e.target.value)} type="password" required />
                        </Box>
                        <Box marginTop="1rem">
                            <Button onClick={handleGoToAdminPanel} variant="contained" color="primary">
                                Acceder
                            </Button>
                        </Box>
                    </FormControl>
                </Box>
            </Paper>
        </Box>
    );
};

export default Login;