import React from 'react';
import ReactDOM from 'react-dom';
import { HomePage } from './pages/Home';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Box, Backdrop, Container } from '@material-ui/core';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import Login from './pages/Login';
import AdminPanel from './pages/AdminPanel/AdminPanel';
import { RecoilRoot } from 'recoil';

import './index.css';
import Appbar from './components/appbar/Appbar';
import UsersPanel from './pages/AdminPanel/UsersPanel';

const theme = createTheme();

ReactDOM.render(
  <RecoilRoot>
    <React.Suspense fallback={
        <Backdrop open={true}>
          <CircularProgress />
        </Backdrop>
      }>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <Appbar />
            <Switch>
              <Box className="bg">
                <Container className="container">
                  <Route path="/" exact component={HomePage} />
                  <Route path="/admin" exact component={AdminPanel}/>
                  <Route path="/login" exact component={Login} />
                  <Route path="/users" exact component={UsersPanel} />
                </Container>
              </Box>
            </Switch>
          </ThemeProvider>
      </BrowserRouter>
    </React.Suspense>
  </RecoilRoot>,
  document.getElementById('root')
);

/* setTimeout(() => {
  document.getElementById('bg')?.style.backgroundImage =
}, 3000) */



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
