/* eslint-disable jsx-a11y/iframe-has-title */
import { Box, Dialog, DialogContent, DialogTitle, Divider, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { IClinicsModel } from '../../services/ClinicServices';
import PhoneIcon from '@material-ui/icons/Phone';
import BusinessIcon from '@material-ui/icons/Business';
import { formatPhoneNumber } from '../../utils/formats';
interface IInfoModalComponentProps {
    clinic: IClinicsModel;
    isDialoglOpen: boolean;
    handleOnCloseDialog: () => void;
};


const InfoModalComponent:React.FC<IInfoModalComponentProps> = props => {
    const {isDialoglOpen, handleOnCloseDialog, clinic} = props;
    return (
        <Dialog open={isDialoglOpen} onClose={handleOnCloseDialog} fullWidth maxWidth="lg">
            <DialogTitle>{clinic?.attributes?.name}</DialogTitle>
            <DialogContent>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={4} style={{height:'320px'}}>
                        <iframe style={{border: 'none'}} height="280px" src={clinic?.attributes?.location}/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6">Información:</Typography>
                        <Divider variant="fullWidth" />
                        <Box display="flex" alignItems="center" justifyContent="left" marginTop="10px" marginBottom="10px">
                            <Box >
                                <PhoneIcon /> 
                            </Box>
                            <Box marginLeft="1rem">
                                {clinic?.attributes?.phone.split(',')?.map(x => 
                                    <div>{formatPhoneNumber(x)}</div>
                                )}
                            </Box>
                        </Box>
                        <Divider variant="fullWidth" />
                        <Typography variant="body2">
                            <BusinessIcon /> {clinic?.attributes?.address}
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default InfoModalComponent;