import { Box, Button, Dialog, Divider, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Typography } from '@material-ui/core';
import { add } from 'date-fns';
import format from 'date-fns/format';
import { es } from 'date-fns/locale';
import React, { useState } from 'react';
import { useRecoilCallback } from 'recoil';
import { IClinicsModel } from '../../services/ClinicServices';
import { IPatietnsModel, updatePatientQuery } from '../../services/PatientServices';
import PhoneIcon from '@material-ui/icons/Phone';
import BusinessIcon from '@material-ui/icons/Business';
import { formatPhoneNumber } from '../../utils/formats';

import './styles.css';

interface FormModalComponentProps {
    isOpen: boolean;
    handleOnCloseFormModal: () => void;
    patient: IPatietnsModel;
    clinic?: IClinicsModel;
    hideForm?: boolean;
};

const FormModalComponent:React.FC<FormModalComponentProps> = props => {
    const {isOpen, handleOnCloseFormModal, patient, clinic, hideForm} = props;
    const clinicx = clinic?.attributes || patient?.attributes.clinic;
    const [isValid, setIsValid] = useState<string>('');
    const [isInterested, setIsInterested] = useState<string>('');
    const updatePatientById = useRecoilCallback(({set, snapshot}) => async (patient: any) => {
        await snapshot.getPromise(updatePatientQuery(patient));
    });

    const handleOnSendInfo = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsInterested(e.target.value);
        updatePatientById({...patient, attributes: {...patient.attributes, status: 1, have_driving_course: 0, want_more_info: Number(e.target.value)}});
    };

    const handlePrint = () => {
        window.print();
    }

    return (
        <Dialog maxWidth="lg" fullWidth onClose={() => handleOnCloseFormModal()} open={isOpen}>
            <Box padding="2rem">
                <Box marginBottom="1rem">
                    <Box className="printme">
                        <Typography>
                            Su Folio es: <strong>{patient?.attributes?.appointment_turn}</strong>
                            <p>Gracias <strong>{patient?.attributes?.name}</ strong>, tu cita es el <strong>{patient?.attributes && format(add(Date.parse(patient?.attributes?.appointment), {days: 1}), 'PPPP', {locale: es} )}</strong></p>
                        </Typography>

                        <Typography>Presentarse en ayunas con los siguientes documentos:</Typography>
                        <Grid container spacing={2}> 
                            <Grid item xs={12} sm={6}>
                                <ul>
                                    <li> Acta de nacimiento original</li>
                                    <li> IFE/INE (Copias legibles)</li>
                                    <li> CURP (Copias legibles)</li>
                                    <li> Comprobante de domicilio no mayor a 3 meses (luz, agua, teléfono)</li>
                                    <li> Licencia de Conducir (Copias legibles)</li>               
                                </ul>
                                {patient?.attributes?.seller && <Typography>{`Vendedor: ${patient?.attributes?.seller?.name}`}</Typography>}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Box className="clinic-image" marginTop="-100px">
                                    <img src={clinicx?.image} width="320px" alt="clinic_logo"/>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Divider />
                    <Box className="printme" marginTop="10px" marginBottom="20px">
                        <Grid container spacing={2}>
                            <Grid xs={6} item>
                            <Box display="flex" alignItems="center" justifyContent="left" marginTop="10px" marginBottom="10px">
                                <Box >
                                    <PhoneIcon /> 
                                </Box>
                                <Box marginLeft="1rem">
                                    {
                                    clinicx?.phone.split(',')?.map(x => 
                                        <div>{formatPhoneNumber(x)}</div>
                                    )}
                                </Box>
                            </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body2">
                                    <BusinessIcon /> {clinicx?.address}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Divider />
                        <Box display="flex" alignItems="center" justifyContent="center" margin="10px">
                            <Button onClick={handlePrint} variant="outlined">Imprimir</Button>
                        </Box>
                        <Divider />
                    </Box>
                    <Box className="no-printme" hidden={Boolean(hideForm)} >
                    <FormControl component="fieldset" >
                        <FormLabel >Cuenta con curso de manejo de transporte de carga?</FormLabel>
                        <RadioGroup onChange={e => setIsValid(e.target.value)} value={isValid} row>
                            <FormControlLabel value="0" control={<Radio />} label="Sí" />
                            <FormControlLabel value="1" control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>
                    </Box>
                </Box>
                
                <Box className="no-printme" marginBottom="10px">
                    {isValid === '1' && <FormControl component="fieldset" >
                        <FormLabel>Esta interesado en recibir información?</FormLabel>
                        <RadioGroup onChange={e => handleOnSendInfo(e)} value={isInterested} row>
                            <FormControlLabel value="1" control={<Radio />} label="Sí" />
                            <FormControlLabel value="0" control={<Radio />} label="No" />
                        </RadioGroup>
                    </FormControl>}
                </Box>
                <Divider />
                {isInterested === '0' && <Box marginTop="2rem">
                    <Typography>En Breve nos Contactaremos con usted para brindarle información</Typography>
                    <Typography>Vuelva Pronto, Gracias!</Typography>
                </Box>}
                <Box marginTop="1rem" display="flex" alignItems="end" justifyContent="flex-end">
                    <Button onClick={() => handleOnCloseFormModal()} variant="contained">Aceptar</Button>
                </Box>
            </Box>
        </Dialog>
    );
};

export default FormModalComponent;

