import { Box, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { SerializableParam, useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import DatePickerModalComponent from '../../components/DatePickerModalComponent';
import FormModalComponent from '../../components/formModal/formModalComponent';
import InfoModalComponent from '../../components/InfoModal/InfoModalComponent';
import SnackbarComponent, { useSnackbar } from '../../components/snackbar/SnackbarComponent';
import { IResponseModel } from '../../models/ResponseModel';
import { getClinicByIdQuery, getClinisQuery, IClinicsModel, selectedClinic } from '../../services/ClinicServices';
import { IPatietnsModel, savePatientQuery, selectedPatient } from '../../services/PatientServices';
import { v4 as uuidv4 } from 'uuid';
import './style.css';

export function HomePage() {
  const [isDatePickerOpen, setIsDatePickerOpen] = React.useState<boolean>(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = React.useState<boolean>(false);
  const [isFormModalOpen, setIsFormModalOpen] = React.useState<boolean>(false);
  const [selectedHospital, setSelectedHospital] = useRecoilState(selectedClinic); 
  const [selectedRegisteredPatient, setSelectedRegisteredPatient] = useRecoilState(selectedPatient);
  const hospitals = useRecoilValue(getClinisQuery);
  const {isOpen, message, openSnackBar, severity} = useSnackbar();
  const getClinicById = useRecoilCallback(({set, snapshot}) =>  async (id: string) => {
    const response = await snapshot.getPromise(getClinicByIdQuery(id)).then(res => res);
    setSelectedHospital(response as unknown as IResponseModel<IClinicsModel>);
  });
  const savePatient = useRecoilCallback(({set, snapshot})=> async (params) => {
  const response = await snapshot.getPromise(savePatientQuery(params as SerializableParam));

   if (!response?.exception) {
      setIsDatePickerOpen(false);
      openSnackBar('Registro Exitoso', 'success');
      setIsFormModalOpen(true);
      setSelectedRegisteredPatient(response as unknown as IResponseModel<IPatietnsModel>);
    } else {
      openSnackBar('Error de validación, Verifica los campos' , 'error');
    }
  });
  
  const handleOnOpenDatePicker =  async (id: string, name: string) => {
    setIsDatePickerOpen(true);
    await getClinicById(id+`?${uuidv4()}`);
  };

  const handleOnClose = () => {
    setIsDatePickerOpen(false);
  };


  const handleOnSave = (x: any) => {
    savePatient({...x, phone: `+521${x.phone}`});
  };

  const handleOnOpenInfoModal = async (id: string) => {
    await getClinicById(id+`?${uuidv4()}`);
    setIsInfoModalOpen(true);
  };


  return (
    <Box  height="100%" display="flex" justifyContent="center" alignItems="center">
      <Grid className="cards-container" container spacing={2}>
          {hospitals?.data?.map( (h, index) => (
          <Grid item xs={12} sm={4} key={index}>
            <Card className="card">
              <CardActionArea onClick={() => handleOnOpenDatePicker(h.id, h.attributes.name)}>
                <CardMedia
                  style={{height: 0, paddingTop: '48.25%', margin: '12px'}}
                  image={h.attributes.image}
                  title={h.attributes.name}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    {h.attributes.name}
                  </Typography>
                  <Typography variant="subtitle1" color="#ff7979">{h.attributes.city}, {h.attributes.state}</Typography>
                  <Box marginTop="10px" />
                  <Typography className="card-info" variant="body2" color="textSecondary" component="p">
                    {h.attributes.address}
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button onClick={e => handleOnOpenDatePicker(h.id, h.attributes.name)} size="small" color="primary">
                  Agendar
                </Button>
                <Button onClick={e => handleOnOpenInfoModal(h.id)} size="small" color="primary">
                  Mas información
                </Button>
              </CardActions>
            </Card>
          </Grid>
          ))}
      </Grid>
      {isDatePickerOpen && <Box marginLeft={10}>
        <DatePickerModalComponent 
          clinic={selectedHospital?.data}
          isDatePickerOpen={isDatePickerOpen} 
          handleOnClose={() => handleOnClose()}
          handleOnSave={handleOnSave}
        />
      </Box>}
        <InfoModalComponent 
          clinic={selectedHospital?.data}
          isDialoglOpen={isInfoModalOpen}
          handleOnCloseDialog={() => setIsInfoModalOpen(false)} 
        />
        <FormModalComponent
          isOpen={isFormModalOpen} 
          handleOnCloseFormModal={() => setIsFormModalOpen(false)}
          patient={selectedRegisteredPatient.data}
          clinic={selectedHospital?.data}
        />
        <SnackbarComponent severity={severity} message={message} isOpen={isOpen} />
    </Box>
  );
}
