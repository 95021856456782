import { MenuItem, Select, TextField } from '@material-ui/core';
import { Box } from '@material-ui/system';
import React from 'react';
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import { DesktopDatePicker, LocalizationProvider } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { IClinicUsers } from '../../../services/ClinicServices';

interface IReportBarChart {
    users: IClinicUsers[];
}

const ReportBarChart:React.FC<IReportBarChart> = props => {
    const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

    const data = {
    labels,
    datasets: [
        {
        label: 'Rep',
        data: labels.map(() => Math.floor (Math.random() * 100)),
        backgroundColor: 'rgba(25, 118, 210, 0.5)',
        }
    ],
    };
    return (
        <Box id="ReportBarChart">
            <Box display="flex" alignItems="center" justifyContent="flex-start" marginTop="2rem" className="date-inputs">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                        label="Fecha Inicial"
                        inputFormat="MM/dd/yyyy"
                        value={new Date()}
                        onChange={() => {}}
                        renderInput={(params) => <TextField {...params} />}
                    />
                    <DesktopDatePicker
                        label="Fecha Final"
                        inputFormat="MM/dd/yyyy"
                        value={new Date()}
                        onChange={() => {}}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
            </Box>
            <Box display="flex" alignItems="center"  marginTop="2rem">
                <Box>
                    <Select 
                        defaultValue="none"
                        placeholder="Vendedor">
                        <MenuItem value="none">
                            <span>Selecciona Vendedor</span>
                        </MenuItem>
                        {props?.users.map(x => 
                            <MenuItem value={Number(x.id)} key={x.id}>
                                {x.name}
                            </MenuItem>
                        )}
                    </Select>
                </Box>
            </Box>
            <Box className="chart" >
            <Chart
                type="bar"
                datasetIdKey='id'
                data={data}
            />
            </Box>
        </Box>
    );
};


export default ReportBarChart;