import { Snackbar, Alert, AlertColor } from '@material-ui/core';
import React, { useState } from 'react';

interface ISnackbarComponentProps {
    severity: AlertColor | undefined;
    message: string | undefined;
    isOpen: boolean;
};

const SnackbarComponent:React.FC<ISnackbarComponentProps> = props => {
    // const [isOpen, setIsOpen] = useState<boolean>(props.isOpen);
    const handleClose = () => {
        // setIsOpen(false);
    };

    return (
        <Snackbar 
            open={props.isOpen} 
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }} 
            autoHideDuration={6000} 
            onClose={handleClose}>
            <Alert onClose={handleClose} severity={props.severity} variant="filled">
                {props.message}
            </Alert>
        </Snackbar>
    );
};

export const useSnackbar = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [message, setMessage] = React.useState<string>();
    const [severity, setSeverity] = React.useState<AlertColor>();
    React.useEffect(() => {
        if (isOpen === true) {
            setTimeout(() => {
                setIsOpen(false);
            }, 3000);
        }
    }, [isOpen]);

    const openSnackBar = (msg = 'Something went wrong...', color: AlertColor) => {
        setMessage(msg);
        setSeverity(color);
        setIsOpen(true);
    }
    return { isOpen, message, openSnackBar, severity };
};

export default SnackbarComponent;