import { Badge, Box } from '@material-ui/core';
import { LocalizationProvider, PickersDay } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import CalendarPicker from '@material-ui/lab/CalendarPicker';
import React, { useEffect } from 'react';
import { es } from 'date-fns/locale'
import { IClinicsModel } from '../services/ClinicServices';
import { add, format } from 'date-fns';


import './styles.css';

interface IDatePickerComponentProps {
    handleOnChangeDate: (arg: Date | null, available: number | undefined, available_after: number | undefined) => void;
    limit?: boolean;
    clinic?: IClinicsModel;
};

const DatePickerComponent: React.FC<IDatePickerComponentProps> = props => {
    const {handleOnChangeDate , limit, clinic} =  props;
    const [date, setDate] = React.useState(new Date());
    const today = new Date();
    const maxDateLimit = add(today, {
        months: 2,     
    });

    const getAvailableDates = (date: string | Date) => clinic?.meta?.available_dates?.find(x => 
    {
        return format(add(Date.parse(x.appointment), {days: 1}), 'yyyy-MM-dd') === date
    })?.available;
    const getAvailableDatesAfter = (date: string | Date) => clinic?.meta?.available_afternoon_dates?.find(x => 
        {
            return format(add(Date.parse(x.appointment), {days: 1}), 'yyyy-MM-dd') === date
        })?.available_afternoon;

    useEffect(() => {
        if (limit) {
            const dateFormatted = format(new Date(), 'yyyy-MM-dd');
            handleOnChangeDate(new Date(), getAvailableDates(dateFormatted), getAvailableDatesAfter(dateFormatted));

        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[clinic]);

    const onChangeDate = (date: Date | null) => {
        const dateFormatted = format(date || new Date(), 'yyyy-MM-dd');
        const caca = getAvailableDates(dateFormatted);
        const popo =  getAvailableDatesAfter(dateFormatted);
        setDate(date || new Date());
        handleOnChangeDate(date, caca, popo);
    };

    const disabledDates = (day: Date) => {
        return day.getDay() === 0;
    };
    
    return (
        <Box>
            <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>
                <CalendarPicker
                    className="datePicker"
                    shouldDisableDate={disabledDates} 
                    date={date}
                    minDate={limit ? today : undefined}
                    maxDate={maxDateLimit}
                    onChange={onChangeDate}
                    renderDay={(day, _value, DayComponentProps) => {
                        return (
                            <Badge 
                                key={day.toString()} 
                                overlap="circular" 
                                badgeContent={
                                day.getDay() !== 0  && limit && <div style={{
                                        width:'15px', 
                                        height: '15px', 
                                        borderRadius: '10px', 
                                        background: 'gray', 
                                        color: 'white', 
                                        fontSize: '10px', 
                                        textAlign: 'center', 
                                        paddingTop: '1px'}}>{clinic?.meta?.total_available_dates?.find(x => 
                                            {
                                                return format(add(Date.parse(x.appointment), {hours: 12}), 'yyyy-MM-dd') === format(day, 'yyyy-MM-dd')
                                            })?.available || Number(clinic?.attributes?.total_appointment) + Number(clinic?.attributes?.total_appointment_afternoon || 0)}</div>}>
                                    <PickersDay {...DayComponentProps} />
                            </Badge>
                        )
                    }}
                />
            </LocalizationProvider>
        </Box>
    );
};

export default DatePickerComponent;