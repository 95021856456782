import { Box, Button } from '@material-ui/core';
import { DataGrid, GridColDef, GridSelectionModel, GridToolbarContainer, GridToolbarExport, GridValueGetterParams } from '@mui/x-data-grid';
import React from 'react';
import { useHistory } from 'react-router';
import { SerializableParam, useRecoilCallback, useRecoilValue } from 'recoil';
import { useSnackbar } from '../../components/snackbar/SnackbarComponent';
import UserModalComponent from '../../components/UserModalComponent';
import { deleteUserQuery, getUsersQuery, saveUserQuery } from '../../services/UserServices';
import './style.css';
import * as moment from '@date-io/moment';
import { format } from 'date-fns';
import { add, addDays } from 'date-fns/esm';

const UsersPanel = () => {
    const columns: GridColDef[] = [
        {
          field: 'no_exp',
          headerName: 'No. Expediente',
          width: 150,
          type: 'number'
        },
        {
          field: 'name',
          headerName: 'Nombre',
          width: 150,
        },
        {
          field: 'last_name',
          headerName: 'Apellido Paterno',
          width: 150,
        },
        {
          field: 'last_name2',
          headerName: 'Apellido Materno',
          width: 150,
        },
        {
            field: 'curp',
            headerName: 'CURP',
            width: 200,
          },
          {
            field: 'address',
            headerName: 'Domicilio',
            width: 400,
          },
          {
            field: 'state',
            headerName: 'Estado',
            width: 150,
          },{
            field: 'phone',
            headerName: 'Teléfono',
            width: 120,
          },
          {
            field: 'exp_date',
            headerName: 'Fecha de examen',
            width: 140,
            type: 'date',
            valueGetter: (params: GridValueGetterParams) => params.value && new Date(params.value),
            valueFormatter: (params) => formatDate(params.value),
          },
          {
            field: 'licence_category',
            headerName: 'Categoria de licencia',
            width: 150,
          },
          {
            field: 'vigency_from',
            headerName: 'Vigencia Desde',
            width: 140,
            type: 'date',
            valueGetter: (params: GridValueGetterParams) => params.value && new Date(params.value),
            valueFormatter: (params) => formatDate(params.value),
          },
          {
            field: 'vigency_to',
            headerName: 'Vigencia Hasta',
            width: 110,
            type: 'date',
            valueGetter: (params: GridValueGetterParams) => params.value && new Date(params.value),
            valueFormatter: (params) => formatDate(params.value),
          },
      ];
      
      const formatDate = (date:any) => {
        if(date){
          return format(addDays(date,1),"dd/MM/yyyy");
        }
        return '';
      }
      const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
      const {openSnackBar} = useSnackbar();
      const history = useHistory()

      const handleClickOpenModal = () => {
        setIsModalOpen(true);
      };
      const handleOnClose = () => {
        setIsModalOpen(false);        
        history.go(0);
      };
      const handleOnSave = (x: any) => {
        saveUser({...x, exp_date:x.exp_date || '',vigency_from:x.vigency_from || '', vigency_to:x.vigency_to || '' });
      };
      const handleOnDelete = async () => {
        // eslint-disable-next-line no-restricted-globals
        if(selectedRows && selectedRows?.length > 0 && confirm("Esta seguro de eliminar")){
            const deleteUserByIdResponse = await deleteUserById(""+selectedRows[0]);
            if (!deleteUserByIdResponse?.exception) {
              history.go(0);
            } 
          }
      };
      const deleteUserById = useRecoilCallback(({set, snapshot}) =>  async (id: string) => {
        const response = await snapshot.getPromise(deleteUserQuery(id));
        return response;
    });
      const saveUser = useRecoilCallback(({set, snapshot})=> async (params) => {
      const response = await snapshot.getPromise(saveUserQuery(params as SerializableParam));
    
         if (!response?.exception) {
            openSnackBar('Registro Exitoso', 'success');
          } else {
            openSnackBar('Error de validación, Verifica los campos' , 'error');
          }
          
        });

      const users = useRecoilValue(getUsersQuery);
      
      const [selectedRows, setSelectedRows] = React.useState<GridSelectionModel>();
      
    return (
        <Box style={{paddingTop: '1rem',height: 600, width: '100%'}}>
            <Button variant="contained" onClick={e => handleClickOpenModal()}>Agregar Usuario</Button>
            <Button variant="contained" color="error" style={{marginLeft:"1rem"}} onClick={e => handleOnDelete()}>Eliminar Usuario</Button>
            <DataGrid
                rows={users.data}
                columns={columns}
                
                experimentalFeatures={{ newEditingApi: true }}
                style={{width: '100%'}}
                components={{
                  Toolbar: CustomToolbar,
                }}
                localeText={{
                  toolbarExportCSV: "Descargar CSV",
                }}
                onSelectionModelChange={(ids) => {
                  setSelectedRows(ids);
                }}
            />
            <UserModalComponent 
            isModalOpen={isModalOpen} 
            handleOnClose={() => handleOnClose()}
            handleOnSave={handleOnSave}
            usersData={users.data}
            />
        </Box>
    );
};
function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport printOptions={{ disableToolbarButton: true }} csvOptions />
    </GridToolbarContainer>
  );
}
export default UsersPanel;