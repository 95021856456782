import Cookies from "js-cookie";
import { atom, selector, selectorFamily } from "recoil";
import { IResponseModel } from "../models/ResponseModel";



const token = Cookies.get('token');

export interface IClinicUsers {
    created_at: string;
    curp: string;
    email: string;
    email_verified_at: string;
    id: number;
    name: string;
    phone: string
    updated_at: string;
}
export interface IClinicsMetaModel {
    clinic_id: number;
    appointment: string;
    available: number;
    available_afternoon: number;
}
export interface IClinicsModel {
    id: string;
    attributes: {
        address: string;
        name: string;
        phone: string;
        image: string;
        location: string;
        users: IClinicUsers[];
        city: string;
        state: string;
        company: string;
        company_contact: string;
        company_phone: string;
        apto_med: string;
        total_appointment: number;
        total_appointment_afternoon: number;
        report_link: string;
    },
    meta: {
        available_dates: IClinicsMetaModel[];
        available_afternoon_dates: IClinicsMetaModel[];
        total_available_dates: IClinicsMetaModel[];
    }
};

const getClinics = async (id?: string)  => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/clinics${id ? `/${id}` :''}`, {
        headers:{
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Credentials': 'same-origin'
        },
    });
    return response.json();
};

const getClinicsAdmin = async (id?: string)  => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/clinics`, {
        headers:{
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Credentials': 'same-origin',
            'Authorization': `bearer ${token}`,
        },
    });
    return response.json();
};

const updateClinic = async (id: string) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/clinics/${id}`, {
        method: 'PUT',
        headers:{
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Credentials': 'same-origin'
        },
    });
    return response.json();
};


export const getClinisQuery = selector<IResponseModel<IClinicsModel[]>>({
    key: 'getClinics',
    get: async({get}) => {
        const response = await getClinics();
        return response;
    }
});

export const selectedClinic = atom({
    key: 'clinicById',
    default: {} as IResponseModel<IClinicsModel>
});

export const getClinicByIdQuery = selectorFamily({
    key: 'getClinicById', 
    get: (id: string) => async ({get}) => {
        const response =  await getClinics(id);
        return response;
    },
    set: (id: string) => ({set, get}, val) => {
        // const response = getClinics(id);
        //get(val as any);
        set(selectedClinic, val as unknown as IResponseModel<IClinicsModel>);
        // console.log(response);
    },
});

export const updateClinicQuery = selectorFamily({
    key: 'updateClinicById', 
    get: (id: string) => async ({get}) => {
        const response =  await updateClinic(id);
        return await response.json();
    },
    set: (id: string) => ({set, get}, val) => {
        // const response = getClinics(id);
        //get(val as any);
        set(selectedClinic, val as unknown as IResponseModel<IClinicsModel>);
        // console.log(response);
    },
});

export const getClinisAdminQuery = selector<IResponseModel<IClinicsModel[]>>({
    key: 'getClinicsAdmin',
    get: async({get}) => {
        const response = await getClinicsAdmin();
        return response;
    }
});

export const clinics = atom({
    key: 'clinics',
    default: {} as IResponseModel<IClinicsModel[]>
});



